import React from "react";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams
} from "@mui/x-data-grid-pro";
import {
  useMutationOrderTransactionSetIsReconciled,
  useQueryOrderTransactionFormOptions
} from "../../api/OrderTransaction";
import { Card, Checkbox, Chip, Link } from "@mui/material";
import { get } from "lodash";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { endOfDay, format, isValid, parseISO, startOfDay, subDays } from "date-fns";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { getGridOperatorsOnlyBy } from "../../utils/getGridStringOperatorsOnlyBy.ts";
import { DateRangeOperator } from "../../components/data-grid-v2/DateRangeOperator.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";

const BankReconciliationPaymentsList = () => {
  useSetPageTitle("Bank Reconciliation");

  const { data: formOptions } = useQueryOrderTransactionFormOptions();
  const { mutateAsync: setTransactionReconciled } = useMutationOrderTransactionSetIsReconciled();

  const columns: GridColDef[] = [
    {
      field: "order.reference_number",
      headerName: "Order #",
      valueGetter: (value, row) => row.order.reference_number,
      filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"]),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const { value, row } = params;
        return (
          <Link
            component={RouterLink}
            underline="hover"
            to={generatePath("/order/:uuid/finalized-quote", {
              uuid: row.order?.uuid
            })}
          >
            {value}

            {!!row?.is_test_mode && (
              <Chip label="Test" size="small" color="warning" sx={{ ml: 1 }} />
            )}
          </Link>
        );
      }
    },
    {
      field: "date",
      headerName: "Date Received",
      width: 180,
      valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
      valueGetter: (_, row) => parseISO(row.date),
      filterOperators: [DateRangeOperator, ...getGridDateOperators()]
    },
    {
      field: "reference_number",
      headerName: "Receipt #",
      width: 200,
      sortable: false,
      valueGetter: (value, row) => row.reference_number,
      renderCell: (params: GridRenderCellParams) => {
        const { value, row } = params;
        return (
          <Link
            underline="hover"
            href={generatePath("/guest/order-transaction/:uuid/print-receipt", {
              uuid: row.uuid
            })}
            target="_blank"
          >
            {value}
          </Link>
        );
      }
    },
    {
      field: "transaction_for",
      headerName: "Receipt Type",
      width: 100,
      type: "singleSelect",
      valueOptions: formOptions?.transaction_for,
      filterOperators: getGridSingleSelectMoreOperators()
    },
    {
      field: "notes",
      headerName: "Description",
      sortable: false,
      flex: 1
    },
    {
      field: "payment_method_name",
      headerName: "Payment Method",
      width: 180,
      valueGetter: (value, row) => get(row, "payment_method.name", row.payment_method_raw),
      type: "singleSelect",
      valueOptions: formOptions?.payment_method_names,
      filterOperators: getGridSingleSelectMoreOperators()
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      valueFormatter: value => toDecimalFromFloat(value || 0),
      filterOperators: getGridNumericOperators()
    },
    {
      field: "is_reconciled_at",
      headerName: "Reconciled",
      width: 100,
      filterable: true,
      filterOperators: [DateRangeOperator, ...getGridDateOperators()],
      renderCell: ({ row, api }: GridRenderCellParams) => (
        <Checkbox
          checked={!!row?.is_reconciled_at}
          onChange={(e, checked) => {
            api.updateRows([{ ...row, is_reconciled_at: checked ? new Date() : null }]);
            api.setLoading(true);
            setTransactionReconciled({
              uuid: row.uuid,
              is_reconciled_at: row?.is_reconciled_at ? null : new Date()
            });
          }}
        />
      )
    }
  ];

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="order-transaction"
        autosizeOnMount={false}
        columns={columns}
        checkboxSelection={false}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "date",
                sort: "desc"
              }
            ]
          },
          filter: {
            filterModel: {
              items: [
                {
                  field: "date",
                  value: [subDays(startOfDay(new Date()), 1), endOfDay(new Date())],
                  id: "date_between",
                  operator: "date_between"
                }
              ]
            }
          }
        }}
        slots={{
          toolbar: DataGridToolbar
        }}
      />
    </Card>
  );
};

export default BankReconciliationPaymentsList;
