import { SidebarItemsType } from "../../types/sidebar";

import { AlignJustify, Calendar as CalendarIcon, Users } from "react-feather";
import DashboardIcon from "@mui/icons-material/Dashboard";

const pagesSection = [
  {
    href: "/customers",
    icon: Users,
    title: "Customers",
    permissionSubject: "customers"
  },

  {
    href: "/lead",
    icon: AlignJustify,
    title: "Leads",
    children: [
      {
        href: "/lead-calendar",
        icon: CalendarIcon,
        title: "Calendar",
        permissionSubject: "lead"
      },
      {
        href: "/lead",
        icon: AlignJustify,
        title: "Leads",
        permissionSubject: "lead"
      }
    ]
  },
  {
    icon: AlignJustify,
    title: "Orders",
    permissionSubject: "order",
    children: [
      {
        icon: AlignJustify,
        title: "Orders",
        href: "/order",
        permissionSubject: "order"
      },
      {
        href: "/dashboard/sales-rep",
        icon: DashboardIcon,
        title: "Dashboard",
        permissionSubject: "dashboard_sales_rep"
      }
    ]
  },
  {
    icon: AlignJustify,
    title: "Accounts",
    children: [
      {
        href: "/outstanding-orders",
        icon: AlignJustify,
        title: "Outstanding Orders",
        permissionSubject: "order_outstanding"
      },
      {
        href: "/bank-reconciliation",
        icon: AlignJustify,
        title: "Bank Reconciliation",
        permissionSubject: "bank_reconciliation"
      },
      {
        href: "/order-receivable",
        icon: AlignJustify,
        title: "Receivables",
        permissionSubject: "order_receivable"
      }
    ]
  },

  {
    icon: AlignJustify,
    title: "Scheduling",
    children: [
      {
        href: "/order-fitting",
        icon: AlignJustify,
        title: "Fitting",
        permissionSubject: "order_fitting"
      }
      /*   {
        href: "/invoiced-incomplete",
        icon: AlignJustify,
        title: "Invoiced incomplete",
        permissionSubject: "invoiced-incomplete"
      },
      {
        href: "/runs",
        icon: AlignJustify,
        title: "Runs",
        permissionSubject: "runs"
      },
      {
        href: "/claims",
        icon: AlignJustify,
        title: "Claims",
        permissionSubject: "claims"
      } */
    ]
  },

  /*  {
    icon: AlignJustify,
    title: "Operations(TBC)",
    children: [
      {
        href: "/production",
        icon: AlignJustify,
        title: "Production(TBC)",
        permissionSubject: "production"
      },
      {
        href: "/production-sheet",
        icon: AlignJustify,
        title: "Production Sheet(TBC)",
        permissionSubject: "production-sheet"
      },
      {
        href: "/ncr",
        icon: AlignJustify,
        title: "NCR(TBC)",
        permissionSubject: "ncr"
      }
    ]
  },
 */
  {
    href: "/data-export",
    icon: AlignJustify,
    title: "Data",
    permissionSubject: "data_export"
  },

  {
    icon: AlignJustify,
    title: "Configuration",
    children: [
      {
        href: "/users",
        icon: Users,
        title: "Users",
        permissionSubject: "users"
      },
      {
        icon: AlignJustify,
        title: "Customers",
        children: [
          {
            href: "/customer-groups",
            icon: Users,
            title: "Customer Groups",
            permissionSubject: "customer_groups"
          }
        ]
      },

      {
        icon: AlignJustify,
        title: "Leads",
        children: [
          {
            href: "/lead-status",
            icon: AlignJustify,
            title: "Lead Status",
            permissionSubject: "lead_status"
          },
          {
            href: "/lead-source",
            icon: AlignJustify,
            title: "Lead Source",
            permissionSubject: "lead_source"
          },
          {
            href: "/marketing",
            icon: AlignJustify,
            title: "Marketing",
            permissionSubject: "marketing"
          }
        ]
      },

      {
        href: "/product",
        icon: AlignJustify,
        title: "Product",
        children: [
          {
            href: "/product",
            icon: AlignJustify,
            title: "Products",
            permissionSubject: "product"
          },
          {
            href: "/inventory-item",
            icon: AlignJustify,
            title: "Inventory Items",
            permissionSubject: "inventory_item"
          },
          {
            href: "/product-line",
            icon: AlignJustify,
            title: "Product Lines",
            permissionSubject: "product_line"
          }
        ]
      },

      {
        icon: AlignJustify,
        title: "Company",
        children: [
          {
            href: "/company",
            icon: AlignJustify,
            title: "Company",
            permissionSubject: "company"
          },

          {
            href: "/payment-term-config",
            icon: AlignJustify,
            title: "Payment Terms",
            permissionSubject: "payment_term_config"
          },
          {
            href: "/pages",
            icon: AlignJustify,
            title: "Pages",
            permissionSubject: "page"
          },
          {
            href: "/fitting-error-reason",
            icon: AlignJustify,
            title: "Fitting Error Reasons",
            permissionSubject: "fitting_error_reason"
          }
        ]
      },

      {
        icon: AlignJustify,
        title: "Debugging",
        children: [
          {
            href: "/logs/emails",
            icon: AlignJustify,
            title: "Emails",
            permissionSubject: "email"
          },
          {
            href: "/key-value",
            icon: AlignJustify,
            title: "Key Values",
            permissionSubject: "key_value"
          },
          {
            href: "/event-store",
            icon: AlignJustify,
            title: "Event Store",
            permissionSubject: "event_store"
          }
          /*  {
            href: "/short-cuts",
            icon: AlignJustify,
            title: "Short cuts (TVC)",
            permissionSubject: "short_cuts"
          } */
        ]
      }
    ]
  }
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection
  }
];

export default navItems;
