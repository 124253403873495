import {
  gridFilterModelSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import React, { Suspense, useDeferredValue, useState } from "react";
import { FormControl, InputLabel, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { endOfDay, isValid, parseISO } from "date-fns";
import { ToolbarStatistics } from "./ToolbarStatistics.tsx";
import Loader from "../../components/Loader.tsx";
import { useQueryOrderTransactionFormOptions } from "../../api/OrderTransaction.ts";

export const DataGridToolbar = () => {
  const apiRef = useGridApiContext();
  const [reconciled, setReconciled] = useState("");

  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterItemDate = filterModel?.items?.find(
    item => item.field === "date" && item.id === "date_between"
  );

  const deferredFilterDate = useDeferredValue(
    filterItemDate?.value?.map((val: string | Date) =>
      isValid(val) ? val : parseISO(val as string)
    ) ?? null
  );

  const filterItemPaymentMethodName = filterModel?.items?.find(
    item => item.field === "payment_method_name" && item.operator === "is"
  );
  const deferredFilterPaymentMethodName = useDeferredValue(
    filterItemPaymentMethodName?.value ?? ""
  );

  const { data: formOptions } = useQueryOrderTransactionFormOptions();

  return (
    <Stack direction="column" gap={3} m={3} my={4}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
        flexGrow={1}
      >
        <DateRangePicker
          localeText={{ start: "Date From", end: "To" }}
          value={deferredFilterDate}
          onChange={value => {
            if (value.every(val => val !== null)) {
              const [D1, D2] = value as [Date, Date];

              apiRef.current.upsertFilterItem({
                field: "date",
                value: [D1, endOfDay(D2)],
                id: "date_between",
                operator: "date_between"
              });
            }
          }}
          slotProps={{
            textField: {
              size: "small",
              sx: { width: 100 }
            }
          }}
        />

        <FormControl size="small">
          <InputLabel
            id="filter-customer-group-label"
            shrink
            margin="dense"
            sx={{ backgroundColor: "white", padding: 0.5 }}
          >
            Payment Methods
          </InputLabel>
          <ToggleButtonGroup
            value={deferredFilterPaymentMethodName}
            size="small"
            exclusive
            onChange={(e, value) => {
              if (value === null && filterItemPaymentMethodName) {
                apiRef.current.deleteFilterItem(filterItemPaymentMethodName);
              } else {
                apiRef.current.upsertFilterItem({
                  field: "payment_method_name",
                  value,
                  id: "payment_method_name",
                  operator: "is"
                });
              }
            }}
          >
            {formOptions?.payment_method_names?.map((payment_method: string) => {
              return (
                <ToggleButton key={payment_method} value={payment_method}>
                  {payment_method}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </FormControl>

        <FormControl size="small">
          <InputLabel
            id="filter-customer-group-label"
            shrink
            margin="dense"
            sx={{ backgroundColor: "white", padding: 0.5 }}
          >
            Reconcile
          </InputLabel>
          <ToggleButtonGroup
            value={reconciled}
            size="small"
            exclusive
            onChange={(event, value) => {
              setReconciled(value);
              if (value !== null) {
                apiRef.current.upsertFilterItem({
                  field: "is_reconciled_at",
                  value: value === "reconciled" ? "!" : "",
                  id: "is_reconciled_at",
                  operator: "is"
                });
              } else {
                apiRef.current.deleteFilterItem({
                  field: "is_reconciled_at",
                  value: "!",
                  id: "is_reconciled_at",
                  operator: "is"
                });
              }
            }}
          >
            <ToggleButton value={"reconciled"}>{"Reconciled"}</ToggleButton>
            <ToggleButton value={"unreconciled"}>{"Unreconciled"}</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>

        <GridToolbarContainer>
          <GridToolbarQuickFilter debounceMs={500} sx={{ width: 150 }} />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>

      <Stack gap={3} mt={3} direction="row" flexWrap="wrap" flexGrow={1}>
        <Suspense fallback={<Loader />}>
          <ToolbarStatistics />
        </Suspense>
      </Stack>
    </Stack>
  );
};
