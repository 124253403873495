import React, { Suspense, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { filesize } from "filesize";
import { createConfirmation } from "../../components/ReactConfirmMountPoint.ts";
import { useQueryOneDataExport } from "../../api/DataExport.ts";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { useTimeout } from "usehooks-ts";

export interface ExportedRunsProps {
  title: string;
  export_uuid?: string;
  description?: string;
}

export const ExportedDialogComponent = ({
  show,
  proceed,
  title,
  description,
  export_uuid
}: ConfirmDialogProps<ExportedRunsProps, false>) => {
  const [isDone, setIsDone] = useState(false);
  const channel = useChannel("data_exports");
  useEvent(channel, `${export_uuid}:handled`, () => setIsDone(true));
  useTimeout(() => setIsDone(true), 10000);

  const handleClose = () => {
    // no-op
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth={"lg"} disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!!description && <DialogContentText>{description}</DialogContentText>}

        <List dense>
          {isDone ? (
            <Suspense fallback={<PleaseWait />}>
              <ExportedListItem export_uuid={export_uuid} />
            </Suspense>
          ) : (
            <PleaseWait />
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => proceed(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const ExportedListItem = ({ export_uuid }: Pick<ExportedRunsProps, "export_uuid">) => {
  const { data: exported } = useQueryOneDataExport(export_uuid);

  return (
    <ListItem>
      <ListItemButton component="a" target="_blank" href={exported.file?.location_url}>
        <ListItemAvatar>
          <Avatar>
            <AttachFileIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={exported.file?.name}
          secondary={filesize(exported.file?.size_in_bytes, {
            standard: "jedec"
          })}
        />
      </ListItemButton>
    </ListItem>
  );
};

const PleaseWait = () => (
  <ListItem disabled>
    <ListItemAvatar>
      <Avatar>
        <AttachFileIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={"Please wait..."} />
  </ListItem>
);

export const exportedDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<ExportedRunsProps, false>) => (
    <ExportedDialogComponent {...props} />
  ))
);
