import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDebounceValue } from "usehooks-ts";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { assign } from "lodash";
import { useField, useFormikContext } from "formik";

interface OptionType {
  label: string;
  id: string;
}

interface Props {
  label: string;
  name: string;
  startAdornment?: React.ReactNode;
  helpText?: false | string;
  initialQueryParams: { sort?: string };
  queryKeyApi: string;
  mapRowToOption: (row: any) => { id: string; label: string };
}

export const AutocompleteInfiniteQueryField = ({
  name,
  label,
  helpText,
  startAdornment,
  initialQueryParams,
  queryKeyApi,
  mapRowToOption,
  ...autocompleteProps
}: Props &
  Omit<
    AutocompleteProps<OptionType, undefined, undefined, boolean>,
    "renderInput" | "options"
  >) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = useState<{ id: string; label: string } | null>();
  const [inputValue, setInputValue] = useState("");
  const [quickFilter] = useDebounceValue(inputValue, 800);

  const queryParams = useMemo(() => {
    return assign(
      {
        limit: 25
      },
      initialQueryParams,
      field.value ? { uuid: field.value } : quickFilter ? { quickFilter } : null
    );
  }, [field.value, initialQueryParams, quickFilter]);

  const { data, isFetching, fetchNextPage } = useInfiniteQuery<any>({
    queryKey: [queryKeyApi, "list", queryParams],
    getNextPageParam: (lastPage, allPages) => {
      return (allPages?.length || 0) + 1;
    },
    meta: { initialPage: 1 },
    suspense: false,
    staleTime: 10000
  });

  const rows = useMemo(
    () =>
      (
        data?.pages?.reduce((rows, pageRows) => {
          return [...rows, ...(pageRows?.rows ?? [])];
        }, []) ?? []
      ).map(mapRowToOption),
    [data?.pages, mapRowToOption]
  );

  useEffect(() => {
    if (field.value && !isFetching) {
      const rowValue = rows?.find((row: any) => row.id === field.value);
      if (rowValue) {
        setValue(rowValue);
      }
    }
  }, [field.value, isFetching, rows, value]);

  return (
    <Autocomplete<any>
      open={open}
      fullWidth
      onOpen={handleOpen}
      onClose={handleClose}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.label ?? option}
      getOptionKey={option => option.id}
      options={rows}
      value={value ?? null}
      onChange={(_: any, newValue: any | null, reason) => {
        if (reason === "clear") {
          setValue(null);
          helpers.setValue(null);
        } else {
          setValue(newValue);
          helpers.setValue(newValue?.id);
        }
      }}
      onBlur={field.onBlur}
      loading={isFetching}
      disabled={isFetching}
      inputValue={inputValue}
      ListboxComponent={Listbox}
      ListboxProps={{
        // @ts-expect-error quick
        fetchNextPage
      }}
      filterOptions={x => x}
      onInputChange={(_, newInputValue, reason) => {
        setInputValue(newInputValue);
        if (reason === "input") {
          helpers.setValue(null);
        }
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search..."
            label={label}
            margin="normal"
            helperText={(submitCount && meta.error) || helpText}
            error={Boolean(submitCount && meta.error)}
            name={name}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment
            }}
          />
        );
      }}
      {...autocompleteProps}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Listbox = React.forwardRef(({ children, fetchNextPage, ...props }: any, ref) => {
  const handleContainerOnBottom = useCallback(() => {
    fetchNextPage && fetchNextPage();
  }, [fetchNextPage]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom);

  return (
    <ul ref={containerRef} {...props}>
      {children}
    </ul>
  );
});
