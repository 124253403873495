import {
  predicateKeyList,
  useMutationAbstract,
  useMutationAddModelV2,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModelV2,
  useMutationEditModel,
  useMutationRestoreDeletedModel,
  useQueryFormOptionsModel,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import { Query, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY as QUERY_KEY_FILES } from "./File";
import { QUERY_KEY as QUERY_KEY_ORDER_LINE_ITEM } from "./OrderLineItem";
import { useEventHandledPromise } from "../hooks/useEventHandledPromise.ts";
import { useChannel } from "@harelpls/use-pusher";

export const QUERY_KEY = "order";

export const useQueryOrdersStatistics = (query: any) =>
  useQuery<{ totalValue: number }>({
    queryKey: [`${QUERY_KEY}/statistics`, "statistics", query]
  });

export const useQueryOutstandingOrdersStatistics = (query: any) =>
  useQuery<{ depositsHeld: number; totalValue: number }>({
    queryKey: [`${QUERY_KEY}/outstanding-statistics`, "statistics", query]
  });

export const useQueryOrderFormOptions = () =>
  useQueryFormOptionsModel<{
    customer_groups: any;
    products: any;
    order_status: any;
    order_types: string[];
    sales_rep_users: any;
    fitter_users: any;
  }>(QUERY_KEY);

export const useQueryOrderFormOptionsForOutstanding = () => {
  return useQuery<{
    customer_groups: any;
    order_types: string[];
    sales_rep_users: any;
  }>({
    queryKey: [QUERY_KEY, "form-options-outstanding"],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/form-options-outstanding`, {
          signal
        })
        .then(({ data }) => data),
    staleTime: 30000,
    meta: { cache: false }
  });
};

export const useQueryOneOrder = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);
export const useQueryOneOrderPublic = (uuid?: string) => {
  return useQuery({
    enabled: !!uuid,
    queryKey: [QUERY_KEY, "one", uuid],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/${uuid}/public`, {
          signal
        })
        .then(({ data }) => data),
    retry: 2
  });
};

export const useMutationAddOrder = (uuid: string) =>
  useMutationAddModelV2<any>(QUERY_KEY, uuid, { pusherChannelName: "OrderCreated" });

export const useMutationAddOrderFromLead = (uuid: string) => {
  type Response = any;

  const channel = useChannel("OrderCreated");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios
        .post<Response>(
          `/api/${QUERY_KEY}/from-lead`,
          {
            ...data,
            uuid
          },
          {
            headers: {
              "Idempotency-Key": `add-${uuid}`
            }
          }
        )
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        })
  });
};

export const useMutationEditOrder = (uuid: string) =>
  useMutationEditModel(QUERY_KEY, uuid, { pusherChannelName: "OrderUpdated" });

export const useMutationDeleteOrder = () => {
  return useMutationDeleteModelV2(QUERY_KEY, { pusherChannelName: "OrderDeleted" });
};

export const useMutationRestoreDeletedOrder = () => useMutationRestoreDeletedModel(QUERY_KEY);

export const useMutationCopyOrder = () => {
  interface Response {
    copied_uuid: string;
  }

  const channel = useChannel("OrderCopied");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<
    Response,
    unknown,
    { uuid: string; order_line_item_uuids?: string[]; order_type: string }
  >({
    mutationKey: [QUERY_KEY, "copy"],
    mutationFn: ({ uuid, ...input }) =>
      axios
        .post<Response>(`/api/${QUERY_KEY}/${uuid}/copy`, input)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(2500, data)
          ]);
        })
  });
};

export const useMutationSplitOrder = () => {
  const queryClient = useQueryClient();

  return useMutationAbstract<
    any,
    unknown,
    { uuid: string; left_order_line_item_uuids: string[]; right_order_line_item_uuids: string[] }
  >({
    mutationKey: [QUERY_KEY, "split"],
    mutationFn: ({ uuid, ...input }) =>
      axios
        .post(`/api/${QUERY_KEY}/${uuid}/split`, input)
        .then(({ data }) => data)
        .then(async data => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          await queryClient.invalidateQueries({
            predicate: predicateKeyList(QUERY_KEY)
          });

          await queryClient.invalidateQueries({
            predicate: query =>
              query.queryKey[0] === QUERY_KEY && query.queryKey[1] === "by-order-number"
          });

          return data;
        })
  });
};

// TODO: Not used
export const useMutationReworkOrder = () => {
  const queryClient = useQueryClient();

  return useMutationAbstract({
    mutationKey: [QUERY_KEY, "rework"],
    mutationFn: uuid =>
      axios
        .post(`/api/${QUERY_KEY}/${uuid}/rework`)
        .then(({ data }) => data)
        .then(async data => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          await queryClient.invalidateQueries({
            predicate: predicateKeyList(QUERY_KEY)
          });

          await queryClient.invalidateQueries({
            predicate: query =>
              query.queryKey[0] === QUERY_KEY && query.queryKey[1] === "by-order-number"
          });

          return data;
        })
  });
};

export const useMutationOrderOverrideSoldPrice = () => {
  interface Response {
    uuid?: string;
    sold_price?: number;
  }

  const channel = useChannel("SoldPriceOverridden");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "rework"],
    mutationFn: ({ uuid, sold_price }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/override-sold-price`, {
          sold_price
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: {
      predicate: (query: Query) => {
        const [queryKey] = query.queryKey;

        return [QUERY_KEY, QUERY_KEY_ORDER_LINE_ITEM].includes(queryKey as string);
      }
    }
  });
};

export const useMutationOrderOverrideDiscountPercentage = () => {
  interface Response {
    uuid?: string;
    discount?: number;
  }

  const channel = useChannel("DiscountPercentageOverridden");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "override-discount"],
    mutationFn: ({ uuid, discount }) =>
      axios
        .put(`/api/${QUERY_KEY}/${uuid}/override-discount-percentage`, {
          discount
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: {
      predicate: (query: Query) => {
        const [queryKey] = query.queryKey;

        return [QUERY_KEY, QUERY_KEY_ORDER_LINE_ITEM].includes(queryKey as string);
      }
    }
  });
};

export const useMutationOrderSetIsQuoteFinalized = () => {
  interface Response {
    uuid?: string;
    is_quote_finalized?: boolean;
    is_quote_finalized_at: Date | null;
  }

  const channel = useChannel("IsQuoteFinalizedSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "set-is-quote-finalized"],
    mutationFn: ({ uuid, is_quote_finalized, is_quote_finalized_at }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-is-quote-finalized`, {
          is_quote_finalized,
          is_quote_finalized_at
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationOrderSetIsInvoiced = () => {
  interface Response {
    uuid?: string;
    is_invoiced?: boolean;
    is_invoiced_at: Date | null;
  }

  const channel = useChannel("IsInvoicedSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "set-is-invoiced"],
    mutationFn: ({ uuid, is_invoiced, is_invoiced_at }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-is-invoiced`, {
          is_invoiced,
          is_invoiced_at
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationOrderSetIsActive = () => {
  interface Response {
    uuid: string;
    active: true | null;
  }

  const channel = useChannel("IsActiveSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "set-is-active"],
    mutationFn: ({ uuid, active }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-is-active`, {
          active
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationOrderSetIsOnHold = () => {
  interface Response {
    uuid?: string;
    is_on_hold_at: Date | null;
  }

  const channel = useChannel("IsOnHoldSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "set-is-on-hold"],
    mutationFn: ({ uuid, is_on_hold_at }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-is-on-hold`, {
          is_on_hold_at
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: ["order/outstanding"]
  });
};

export const useMutationOrderSetNotesOutstanding = () => {
  interface Response {
    uuid?: string;
    notes_outstanding: string | null;
  }

  const channel = useChannel("NotesOutstandingSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "set-notes-outstanding"],
    mutationFn: ({ uuid, notes_outstanding }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-notes-outstanding`, {
          notes_outstanding
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: ["order/outstanding"]
  });
};

export const useMutationAddFileAttachmentOrder = () => {
  type Response = any;

  const channel = useChannel("FileCreated");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "add-file-attachment"],
    mutationFn: ({ order_uuid, ...data }: any) =>
      axios
        .post<Response>(`/api/${QUERY_KEY}/${order_uuid}/add-file-attachment`, data)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(order_uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: {
      predicate: (query: Query) => {
        const [queryKey] = query.queryKey;

        return [QUERY_KEY, QUERY_KEY_FILES].includes(queryKey as string);
      }
    }
  });
};

export const useMutationAcceptOrder = () => {
  const channel = useChannel("OrderAccepted");
  const eventHandledPromise = useEventHandledPromise(channel);

  return useMutationAbstract<unknown, unknown, string>({
    mutationKey: [QUERY_KEY, "accept-order"],
    mutationFn: uuid =>
      axios
        .post(`/api/${QUERY_KEY}/${uuid}/accept`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race([eventHandledPromise(uuid as string, data), sleep(1500, data)]);
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationCancelOrder = () => {
  interface Response {
    uuid: string;
  }

  const channel = useChannel("OrderCancelled");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "cancel-order"],
    mutationFn: ({ uuid }) =>
      axios
        .post<Response>(`/api/${QUERY_KEY}/${uuid}/cancel-order`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationSetCreditAmountOrder = () => {
  type Response = any;

  const channel = useChannel("CreditSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<
    Response,
    unknown,
    { uuid: string; credit: number | null; credit_note: string | null }
  >({
    mutationKey: [QUERY_KEY, "set-credit-amount"],
    mutationFn: ({ uuid, ...input }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-credit-amount`, input)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationBulkDeleteOrder = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useMutationProcessOrder = () => {
  interface Response {
    uuid: string;
  }

  const channel = useChannel("OrderProcessed");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "process-order"],
    useErrorBoundary: false,
    mutationFn: ({ uuid }) =>
      axios
        .post<Response>(`/api/${QUERY_KEY}/${uuid}/process-order`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: ["order/outstanding"]
  });
};

export const useMutationUnProcessOrder = () => {
  interface Response {
    uuid: string;
  }

  const channel = useChannel("OrderUnProcessed");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "unprocess-order"],
    useErrorBoundary: false,
    mutationFn: ({ uuid }) =>
      axios
        .post<Response>(`/api/${QUERY_KEY}/${uuid}/unprocess-order`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: ["order/outstanding"]
  });
};
