import {
  useMutationAbstract,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModelsV2
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY as ORDER_QUERY_KEY } from "./Order";
import sleep from "../utils/sleep";
import { useEventHandledPromise } from "../hooks/useEventHandledPromise.ts";
import { useChannel } from "@harelpls/use-pusher";

export const QUERY_KEY = "order-transaction";

export const useQueryOrderTransactionsStatistics = (query: any) =>
  useQuery<any>({
    queryKey: [`${QUERY_KEY}/statistics`, "statistics", query]
  });

export const useQueryOrderTransactionsByOrderID = (order_id?: number) =>
  useQueryModelsV2<any>(
    QUERY_KEY,
    {
      order_id,
      page: 1,
      limit: 100
    },
    {
      enabled: !!order_id
    }
  );

export const useQueryOneOrderTransactionForReceipt = (uuid?: string) => {
  return useQuery({
    enabled: !!uuid,
    queryKey: [QUERY_KEY, "one-for-receipt", uuid],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/${uuid}/receipt`, {
          signal
        })
        .then(({ data }) => data),
    retry: 2
  });
};

export const useMutationAddOrderTransactionDebit = () => {
  const queryClient = useQueryClient();

  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios
        .post(`/api/${QUERY_KEY}/add-debit`, data)
        .then(({ data }) => data)
        .then(async response => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          await queryClient.invalidateQueries([QUERY_KEY, "one"]);
          await queryClient.invalidateQueries([ORDER_QUERY_KEY, "one"]);

          return response;
        })
  });
};

export const useMutationAddOrderTransactionCredit = () => {
  const queryClient = useQueryClient();

  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios
        .post(`/api/${QUERY_KEY}/add-credit`, data)
        .then(({ data }) => data)
        .then(async response => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          await queryClient.invalidateQueries([QUERY_KEY, "one"]);
          await queryClient.invalidateQueries([ORDER_QUERY_KEY, "one"]);

          return response;
        })
  });
};

export const useMutationEditOrderTransaction = (uuid: string) =>
  useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteOrderTransaction = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteOrderTransaction = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useQueryOrderTransactionFormOptions = () =>
  useQueryFormOptionsModel<{
    payment_methods: any;
    payment_method_names: string[];
    status: string[];
    transaction_for: ("deposit" | "pre-delivery" | "final" | "reversal")[];
  }>(QUERY_KEY);

export const useMutationOrderTransactionSetIsReconciled = () => {
  interface Response {
    uuid?: string;
    is_reconciled_at: Date | null;
  }

  const channel = useChannel("TransactionReconciledSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response>({
    mutationKey: [QUERY_KEY, "is_reconciled_at"],
    mutationFn: ({ uuid, is_reconciled_at }) =>
      axios
        .put<Response>(`/api/${QUERY_KEY}/${uuid}/set-is-reconciled-at`, {
          is_reconciled_at
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: { queryKey: [QUERY_KEY, "list"], exact: false }
  });
};
