import { Autocomplete, Box, TextField } from "@mui/material";
import { GridFilterInputValueProps, GridFilterOperator } from "@mui/x-data-grid-pro";
import React, { useCallback, useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDebounceValue } from "usehooks-ts";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { assign } from "lodash";

interface InputComponentProps {
  initialQueryParams: { sort?: string };
  queryKeyApi: string;
  mapRowToOption: (row: any) => { id: string; label: string };
}

export const AutoCompleteFilterInput = (props: InputComponentProps): GridFilterOperator => {
  return {
    value: "is_autocomplete",
    label: "is",
    getApplyFilterFn: () => null,
    InputComponent: AutoCompleteInputValue,
    InputComponentProps: props
  };
};

function AutoCompleteInputValue(props: GridFilterInputValueProps & InputComponentProps) {
  const { item, applyValue, initialQueryParams, queryKeyApi, mapRowToOption } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [inputValue, setInputValue] = useState("");
  const [quickFilter] = useDebounceValue(inputValue, 800);
  const queryParams = useMemo(() => {
    return assign(
      {
        limit: 25,
        quickFilter
      },
      initialQueryParams
    );
  }, [initialQueryParams, quickFilter]);

  const { data, isFetching, fetchNextPage } = useInfiniteQuery<any>({
    queryKey: [queryKeyApi, "list", queryParams],
    getNextPageParam: (lastPage, allPages) => {
      return (allPages?.length || 0) + 1;
    },
    meta: { initialPage: 1 },
    suspense: false,
    staleTime: 10000
  });

  const rows = useMemo(
    () =>
      (
        data?.pages?.reduce((rows, pageRows) => {
          return [...rows, ...(pageRows?.rows ?? [])];
        }, []) ?? []
      ).map(mapRowToOption),
    [data?.pages, mapRowToOption]
  );

  return (
    <Box
      sx={useMemo(
        () => ({
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          pl: "10px"
        }),
        []
      )}
    >
      <Autocomplete<any>
        open={open}
        fullWidth
        onOpen={handleOpen}
        onClose={handleClose}
        isOptionEqualToValue={(option, value) => {
          return option.id === value?.id;
        }}
        getOptionLabel={option => option.label}
        getOptionKey={option => option.id}
        options={rows}
        value={item?.value ?? null}
        onChange={(_: any, newValue: any | null, reason) => {
          if (reason === "clear") {
            applyValue({ ...item, value: undefined });
          } else {
            applyValue({ ...item, value: newValue });
          }
        }}
        loading={isFetching}
        inputValue={inputValue}
        ListboxComponent={Listbox}
        ListboxProps={{
          // @ts-expect-error quick
          fetchNextPage
        }}
        filterOptions={x => x}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="Select"
              variant="standard"
              placeholder="Search..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          );
        }}
      />
    </Box>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Listbox = React.forwardRef(({ children, fetchNextPage, ...props }: any, ref) => {
  const handleContainerOnBottom = useCallback(() => {
    fetchNextPage && fetchNextPage();
  }, [fetchNextPage]);

  const containerRef = useBottomScrollListener(handleContainerOnBottom);

  return (
    <ul ref={containerRef} {...props}>
      {children}
    </ul>
  );
});
