import FittingErrorReasonList from "./FittingErrorReasonList";
import FittingErrorReasonAdd from "./FittingErrorReasonAdd";
import FittingErrorReasonEdit from "./FittingErrorReasonEdit";

const routes = [
  {
    path: "/fitting-error-reason",
    element: <FittingErrorReasonList />
  },
  {
    path: "/fitting-error-reason/add",
    element: <FittingErrorReasonAdd />
  },
  {
    path: "/fitting-error-reason/:uuid/edit",
    element: <FittingErrorReasonEdit />
  }
];

export default routes;
