import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "fitting-error-reason";

export const useQueryFittingErrorReasons = () => useQueryModels<any>(QUERY_KEY);

export const useQueryOneFittingErrorReason = (uuid?: string) =>
  useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddFittingErrorReason = (uuid: string) =>
  useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditFittingErrorReason = (uuid: string) =>
  useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteFittingErrorReason = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteFittingErrorReason = () =>
  useMutationBulkDeleteModelV2(QUERY_KEY);
