import React, { forwardRef, useMemo } from "react";
import {
  getGridDateOperators,
  getGridNumericOperators,
  GridColDef,
  GridRenderCellParams
} from "@mui/x-data-grid-pro";
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  IconButton,
  InputBase,
  Link,
  Tooltip
} from "@mui/material";
import { debounce, defaultTo, get, isEmpty } from "lodash";
import {
  useMutationOrderSetNotesOutstanding,
  useMutationProcessOrder,
  useMutationUnProcessOrder,
  useQueryOrderFormOptionsForOutstanding
} from "../../api/Order";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { endOfDay, format, isValid, parseISO, startOfYear } from "date-fns";
import { DateRangeOperator } from "../../components/data-grid-v2/DateRangeOperator.tsx";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";
import { DataGridServerIndexBasedPaginated as BaseDataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { darken, lighten, styled, Theme } from "@mui/material/styles";
import { orderFinalizedPDFDialog } from "../order/components/OrderFinalizedPDFDialog.tsx";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  CustomerAutoCompleteFilter,
  OrderRefNumberAutoCompleteFilter
} from "../../components/data-grid-v2/AutoCompleteFilters.ts";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { Link as MailLink } from "react-router-dom";
import { EmailOutlined as Email } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { attachmentsDialog } from "../order/components/AttachmentsDialog.tsx";

const getBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles("light", {
    backgroundColor: lighten(color, coefficient)
  })
});

const DataGridServerIndexBasedPaginated = styled(BaseDataGridServerIndexBasedPaginated)(
  ({ theme }) => ({
    "& .unprocessed": {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.9),
      "&:hover": {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.7)
      },
      "&.Mui-selected": {
        ...getBackgroundColor(theme.palette.info.main, theme, 0.5),
        "&:hover": {
          ...getBackgroundColor(theme.palette.info.main, theme, 0.4)
        }
      }
    }
  })
);

const OutstandingOrdersList = () => {
  const { mutateAsync: setNotesOutstanding } = useMutationOrderSetNotesOutstanding();
  const { data: formOptions } = useQueryOrderFormOptionsForOutstanding();
  const { enqueueSnackbar } = useSnackbar();
  useSetPageTitle("Outstanding Orders");

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "process",
        headerName: "Process",
        filterable: false,
        sortable: false,
        width: 140,
        renderCell: ({ row }) => {
          const isProcessed = !row.quote_accepted_at || !!row.is_processed_at;

          return [
            !isProcessed ? (
              <Tooltip
                title="Once clicked, it will process without alert confirmation."
                key={`process-${row.uuid}`}
              >
                <ProcessOrderButton uuid={row.uuid} />
              </Tooltip>
            ) : (
              <Tooltip
                title="Once clicked, it will un-process without alert confirmation."
                key={`un-process-${row.uuid}`}
              >
                <UnProcessOrderButton uuid={row.uuid} />
              </Tooltip>
            )
          ];
        }
      },
      {
        field: "reference_number",
        headerName: "Order #",
        valueGetter: (value, row) => row.reference_number,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          const { value, row } = params;
          return (
            <Link
              component={RouterLink}
              underline="hover"
              to={generatePath("/order/:uuid/finalized-quote", {
                uuid: row.uuid
              })}
            >
              {value}

              {!!row?.is_test_mode && (
                <Chip label="Test" size="small" color="warning" sx={{ ml: 1 }} />
              )}
            </Link>
          );
        },
        type: "singleSelect",
        filterOperators: [OrderRefNumberAutoCompleteFilter]
      },

      {
        field: "actions",
        headerName: "Copy",
        filterable: false,
        sortable: false,
        headerAlign: "center",
        align: "center",
        width: 100,
        renderCell: ({ row }) => {
          const { uuid } = row;
          return (
            <CopyToClipboard
              text={`${window.location.origin}${generatePath("/guest/order/:uuid", {
                uuid: uuid ?? null
              })}`}
              onCopy={() =>
                enqueueSnackbar("Copied", {
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  }
                })
              }
            >
              <InsertLinkIcon style={{ color: "black" }} />
            </CopyToClipboard>
          );
        }
      },

      {
        field: "attachments",
        headerName: "Attachments",
        width: 100,
        headerAlign: "center",
        align: "center",
        renderCell: params => {
          const { row } = params;
          const { uuid, id, attachments } = row;
          return (
            <>
              {attachments && attachments?.length !== 0 && (
                <IconButton
                  aria-label="Attachments"
                  onClick={async () =>
                    await attachmentsDialog({
                      title: "Attachments",
                      is_internal: true,
                      order_id: id,
                      order_uuid: uuid,
                      all_attachments: true
                    })
                  }
                >
                  <AttachFileIcon />
                </IconButton>
              )}
            </>
          );
        },
        valueGetter: (value, row, column) => get(row, column.field),
        filterable: false,
        sortable: false
      },

      {
        field: "quote_accepted_at",
        headerName: "Accepted Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.quote_accepted_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },

      {
        field: "customer.customer_group.id",
        headerName: "Customer Group",
        width: 150,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row.customer?.customer_group?.name,
        type: "singleSelect",
        valueOptions: formOptions?.customer_groups?.map((customerGroup: any) => {
          return {
            value: customerGroup.id,
            label: customerGroup.name
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "order_type",
        headerName: "Order Type",
        width: 180,
        valueGetter: (value, row, column) => get(row, column.field),
        type: "singleSelect",
        valueOptions: formOptions?.order_types,
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "is_invoiced_at",
        headerName: "Invoiced Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.is_invoiced_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "customer_id",
        headerName: "Customer",
        width: 180,
        renderCell: params => {
          const {
            row: { customer }
          } = params;
          if (customer) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                {customer.full_name_with_company}
              </Link>
            );
          }
        },
        type: "singleSelect",
        filterOperators: [CustomerAutoCompleteFilter]
      },

      {
        field: "customer.email",
        headerName: "Email",
        width: 100,
        headerAlign: "center",
        align: "center",
        renderCell: params => {
          const { value, row } = params;
          return (
            <MailLink
              to="#"
              onClick={e => {
                window.location.href = `mailto:${value}?subject=Your OneSolomons order ${row.reference_number}`;
                e.preventDefault();
              }}
            >
              <Email style={{ color: "black" }} />
            </MailLink>
          );
        },
        valueGetter: (value, row, column) => get(row, column.field),
        filterable: false,
        sortable: false
      },

      {
        field: "sales_rep_id",
        headerName: "Sales Rep",
        width: 180,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row?.sales_rep?.full_name,
        type: "singleSelect",
        valueOptions: formOptions?.sales_rep_users?.map((user: any) => ({
          value: user.id,
          label: user.full_name
        })),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "sold_price",
        headerName: "Order Total",
        width: 120,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "transactions_sum",
        headerName: "Receipts",
        width: 120,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "outstanding_amount",
        headerName: "Outstanding",
        width: 120,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },

      {
        field: "notes_reference",
        headerName: "Reference",
        width: 180,
        valueGetter: (value, row, column) => get(row, column.field),
        sortable: false,
        filterable: false
      },
      {
        field: "is_processed_at",
        headerName: "Processed By",
        width: 180,
        valueGetter: (_, row) => {
          const isProcessed = !row.quote_accepted_at || !!row.is_processed_at;

          if (!isProcessed) {
            return null;
          }

          return [
            "Processed",
            !!row.processed_by_user && `by ${row.processed_by_user?.full_name}`,
            !!row.is_processed_at && `on ${format(new Date(row.is_processed_at), "dd/MM/yyyy p")}`
          ]
            .filter(Boolean)
            .join(" ");
        }
      },
      {
        field: "notes_outstanding",
        headerName: "Notes",
        width: 300,
        renderCell: ({ row }: GridRenderCellParams) => (
          <InputBase
            defaultValue={defaultTo(row?.notes_outstanding, "")}
            size="small"
            fullWidth
            multiline
            rows={2}
            onKeyDown={e => {
              e.stopPropagation();
            }}
            onChange={debounce(e => {
              setNotesOutstanding({
                uuid: row.uuid,
                notes_outstanding: isEmpty(e.target.value) ? null : e.target.value
              });
            }, 800)}
          />
        )
      }
    ],
    [
      formOptions?.customer_groups,
      formOptions?.order_types,
      formOptions?.sales_rep_users,
      setNotesOutstanding
    ]
  );

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="order/outstanding"
        autosizeOnMount={false}
        columns={columns}
        checkboxSelection={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              "customer.customer_group.id": false,
              order_type: false
            }
          },
          filter: {
            filterModel: {
              items: [
                {
                  field: "quote_accepted_at",
                  value: [
                    startOfYear(new Date(2024, 11)).toISOString(),
                    endOfDay(new Date()).toISOString()
                  ],
                  id: "accepted_date_range",
                  operator: "date_between"
                }
              ]
            }
          },
          sorting: {
            sortModel: [
              {
                field: "reference_number",
                sort: "desc"
              }
            ]
          }
        }}
        slots={{
          toolbar: DataGridToolbar
        }}
        getRowClassName={({ row }) => {
          const isProcessed = !row.quote_accepted_at || !!row.is_processed_at;
          return isProcessed ? "" : "unprocessed";
        }}
      />
    </Card>
  );
};

export default OutstandingOrdersList;

const ProcessOrderButton = forwardRef<HTMLAnchorElement, { uuid: string }>(
  ({ uuid, ...props }: any, ref) => {
    const { mutateAsync: processOrder, isLoading } = useMutationProcessOrder();

    const handleButtonProcessOrder = async () => {
      await processOrder({ uuid });
      await orderFinalizedPDFDialog({
        order_uuid: uuid
      });
    };

    return (
      <Button
        {...props}
        ref={ref}
        size="small"
        title="Process"
        variant="contained"
        disabled={isLoading}
        onClick={handleButtonProcessOrder}
        endIcon={isLoading && <CircularProgress size={18} color="inherit" />}
      >
        Process
      </Button>
    );
  }
);

const UnProcessOrderButton = forwardRef<HTMLAnchorElement, { uuid: string }>(
  ({ uuid, ...props }: any, ref) => {
    const { mutateAsync: unProcessOrder, isLoading } = useMutationUnProcessOrder();

    const handleButtonUnProcessOrder = async () => {
      await unProcessOrder({ uuid });
    };

    return (
      <Button
        {...props}
        ref={ref}
        size="small"
        title="Un-Process"
        variant="outlined"
        disabled={isLoading}
        onClick={handleButtonUnProcessOrder}
        endIcon={isLoading && <CircularProgress size={18} color="inherit" />}
      >
        Un-Process
      </Button>
    );
  }
);
