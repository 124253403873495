import React, { useEffect, useState } from "react";
import { Field, FormikProvider, useFormik, useFormikContext } from "formik";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField as MuiTextField,
  Theme
} from "@mui/material";
import * as Yup from "yup";
import { array } from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { useQueryLeadFormOptions, useQueryOneLead } from "../../api/Lead";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import AutocompleteField from "../../components/AutocompleteField";
import { FieldProps } from "formik/dist/Field";
import { addHours, compareAsc, format, getHours, getMinutes, getSeconds, set } from "date-fns";
import GooglePlacesInput from "../../components/GooglePlacesInput";
import qs from "qs";
import { get } from "lodash";
import useIsLoading from "../../hooks/useIsLoading";
import requiredHelpText from "../../utils/requiredHelpText";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { useTheme } from "@mui/material/styles";
import { Feature } from "use-feature";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { datePickerDialog } from "../../components/DatePickerDialog";
import { timePickerDialog } from "../../components/TimePickerDialog";
import { AutocompleteInfiniteQueryField } from "../../components/AutocompleteInfiniteQueryField.tsx";
import { useQueryOneCustomer } from "../../api/Customer.ts";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface Values {
  lead_number?: number;
  customer_uuid?: string;
  lead_source_uuid?: string;
  marketing_uuids?: string[];
  lead_status_uuid?: string;
  sales_rep_user_uuid?: string;
  appointment_datetime_start?: Date;
  appointment_datetime_end?: Date;
  notes?: string;
  site_address?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name: string, options: readonly string[], theme: Theme) {
  return {
    fontWeight:
      options.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const LeadForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const { data: formOptionsData } = useQueryLeadFormOptions();
  const nextAvailableReferenceNumber = formOptionsData?.next_available_reference_number;
  const [openFormDialog, setOpenFormDialog] = useState<JSX.Element>();
  const { data } = useQueryOneLead(uuid);
  const [searchParams] = useSearchParams();
  const parsedSearchParams = qs.parse(searchParams.toString());
  const isLoading = useIsLoading(["lead"]);
  const { isSuspendMutations } = useHealthChecker();
  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    uuid: Yup.string().optional(),
    lead_number: Yup.number().when("uuid", (uuid, schema) => {
      return uuid ? schema.optional() : schema.required().min(nextAvailableReferenceNumber ?? 0);
    }),
    customer_uuid: Yup.string().uuid().required().label("Customer"),
    lead_source_uuid: Yup.string()
      .uuid()
      .required()
      .label("Lead Source")
      .oneOf(formOptionsData?.lead_source?.map(({ uuid }: any) => uuid) ?? []),
    marketing_uuids: array().of(
      Yup.string()
        .uuid()
        .label("Marketing")
        .oneOf(formOptionsData?.marketing?.map(({ uuid }: any) => uuid) ?? [])
    ),
    lead_status_uuid: Yup.string()
      .uuid()
      .required()
      .label("Lead Status")
      .oneOf(formOptionsData?.lead_status?.map(({ uuid }: any) => uuid) ?? []),
    sales_rep_user_uuid: Yup.string()
      .uuid()
      .label("Sales Rep")
      .required()
      .oneOf(formOptionsData?.sales_rep_user?.map(({ uuid }: any) => uuid) ?? []),
    notes: Yup.string().required(),
    appointment_datetime_start: Yup.date().nullable().optional(),
    appointment_datetime_end: Yup.date().nullable().optional(),
    site_address: Yup.string().max(255).optional()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/lead",
    validationSchema
  });

  const formik = useFormik({
    initialValues: {
      lead_number: nextAvailableReferenceNumber,
      customer_uuid: null,
      lead_source_uuid: null,
      lead_status_uuid: null,
      sales_rep_user_uuid: null,
      site_address: data?.customer?.billing_address,
      notes: "",
      ...(parsedSearchParams?.initialValues as Record<string, string>),
      marketing_uuids: data?.marketings?.map(({ uuid }: any) => uuid) ?? [],
      ...data,
      appointment_datetime_start: data?.appointment_datetime_start
        ? new Date(data?.appointment_datetime_start)
        : null,
      appointment_datetime_end: data?.appointment_datetime_end
        ? new Date(data?.appointment_datetime_end)
        : null
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  });

  const { errors, handleBlur, handleChange, touched, values, dirty, setValues } = formik;

  return (
    <FormikProvider value={formik}>
      <Card mb={6}>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <AutocompleteInfiniteQueryField
              label="Customer"
              name="customer_uuid"
              queryKeyApi="customers"
              initialQueryParams={{ sort: "name" }}
              mapRowToOption={(option: any) => ({
                id: option.uuid,
                label: `${option.full_name_with_company} (${option.email})`
              })}
              fullWidth
              openOnFocus
              helpText={requiredHelpText("customer_uuid", validationSchema)}
              readOnly={!!get(parsedSearchParams, "initialValues.customer_uuid")}
              disabled={isLoading}
            />

            <AutocompleteField
              label="Lead Source"
              name="lead_source_uuid"
              options={formOptionsData?.lead_source?.map(({ uuid, name }: any) => ({
                id: uuid,
                label: name
              }))}
              helpText={requiredHelpText("lead_source_uuid", validationSchema)}
              fullWidth
              openOnFocus
              disabled={isLoading}
            />

            <Field name="marketing_uuids">
              {({ field, form }: FieldProps) => {
                return (
                  <FormControl fullWidth disabled={isLoading} margin="normal">
                    <InputLabel id="marketing-label">Marketing</InputLabel>
                    <Select
                      labelId="marketing-label"
                      multiple
                      value={field.value}
                      onChange={({ target }) => {
                        form.setFieldValue(field.name, target.value);
                      }}
                      input={<OutlinedInput label="Marketing" />}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={
                                formOptionsData?.marketing?.find(({ uuid }: any) => uuid === value)
                                  ?.name
                              }
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {formOptionsData?.marketing?.map(({ uuid, name }: any) => (
                        <MenuItem
                          key={uuid}
                          value={uuid}
                          style={getStyles(name, formOptionsData?.marketing, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            </Field>

            <AutocompleteField
              label="Lead Status"
              name="lead_status_uuid"
              helpText={requiredHelpText("lead_status_uuid", validationSchema)}
              options={formOptionsData?.lead_status?.map(({ uuid, name }: any) => ({
                id: uuid,
                label: name
              }))}
              fullWidth
              openOnFocus
              disabled={isLoading}
            />

            <AutocompleteField
              label="Sales Rep"
              name="sales_rep_user_uuid"
              helpText={requiredHelpText("sales_rep_user_uuid", validationSchema)}
              options={formOptionsData?.sales_rep_user?.map(({ uuid, email }: any) => ({
                id: uuid,
                label: email
              }))}
              fullWidth
              openOnFocus
              disabled={isLoading}
            />

            <Grid container justifyContent="space-between" spacing={4}>
              <Field name="appointment_datetime_start" value={values.appointment_datetime_start}>
                {({ field, form, meta }: FieldProps) => {
                  return (
                    <>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Appointment Date"
                          helperText={
                            (meta.touched && meta.error) ||
                            requiredHelpText(field.name, validationSchema)
                          }
                          error={Boolean(meta.touched && meta.error)}
                          fullWidth
                          margin="normal"
                          value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                          disabled={isLoading}
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={async () => {
                                    const datetime = await datePickerDialog({
                                      title: "Appointment Date",
                                      defaultDate: field.value
                                    });

                                    if (datetime) {
                                      form.setFieldValue(field.name, datetime);

                                      if (values.appointment_datetime_end) {
                                        form.setFieldValue(
                                          "appointment_datetime_end",
                                          set(datetime, {
                                            hours: getHours(
                                              new Date(values.appointment_datetime_end)
                                            ),
                                            minutes: getMinutes(
                                              new Date(values.appointment_datetime_end)
                                            ),
                                            seconds: getSeconds(
                                              new Date(values.appointment_datetime_end)
                                            )
                                          })
                                        );
                                      } else {
                                        form.setFieldValue(
                                          "appointment_datetime_end",
                                          addHours(datetime, 2)
                                        );
                                      }
                                    }
                                  }}
                                  edge="end"
                                >
                                  <CalendarMonthIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Time Start"
                          helperText={
                            (meta.touched && meta.error) ||
                            requiredHelpText(field.name, validationSchema)
                          }
                          error={Boolean(meta.touched && meta.error)}
                          fullWidth
                          margin="normal"
                          value={field.value ? format(field.value, "p") : ""}
                          disabled={!values?.appointment_datetime_start || isLoading}
                          InputLabelProps={{
                            shrink: !!field.value
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={!values?.appointment_datetime_start || isLoading}
                                  onClick={async () => {
                                    const datetime = await timePickerDialog({
                                      title: "Time Start",
                                      defaultDate: field.value
                                    });

                                    if (datetime) {
                                      form.setFieldValue(field.name, datetime);

                                      if (
                                        !!values.appointment_datetime_end &&
                                        compareAsc(datetime, values.appointment_datetime_end) > 0
                                      ) {
                                        form.setFieldValue(
                                          "appointment_datetime_end",
                                          addHours(datetime, 2)
                                        );
                                      }
                                    }
                                  }}
                                  edge="end"
                                >
                                  <AccessTimeIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </>
                  );
                }}
              </Field>

              <Field name="appointment_datetime_end" value={values.appointment_datetime_end}>
                {({ field, form, meta }: FieldProps) => {
                  return (
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Time End"
                        helperText={
                          (meta.touched && meta.error) ||
                          requiredHelpText(field.name, validationSchema)
                        }
                        error={Boolean(meta.touched && meta.error)}
                        fullWidth
                        margin="normal"
                        value={field.value ? format(field.value, "p") : ""}
                        disabled={!values?.appointment_datetime_end || isLoading}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={!values?.appointment_datetime_end || isLoading}
                                onClick={async () => {
                                  const datetime = await timePickerDialog({
                                    title: "Time End",
                                    defaultDate: field.value,
                                    minTime: values.appointment_datetime_start
                                  });

                                  if (datetime) {
                                    form.setFieldValue(field.name, datetime);
                                  }
                                }}
                                edge="end"
                              >
                                <AccessTimeIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  );
                }}
              </Field>
            </Grid>

            <TextField
              name="notes"
              label="Notes"
              value={values.notes ?? ""}
              error={Boolean(touched.notes && errors.notes)}
              fullWidth
              helperText={
                (touched.notes && errors.notes) || requiredHelpText("notes", validationSchema)
              }
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              variant="outlined"
              margin="normal"
              disabled={isLoading}
            />

            <GooglePlacesInput
              label="Site Address"
              name="site_address"
              helpText={requiredHelpText("site_address", validationSchema)}
              disabled={isLoading}
            />

            <FormGroup>
              <FormControlLabel
                control={<CheckboxSiteAddrSameAsBillingAddr data={data} />}
                label="Same as billing address"
              />
            </FormGroup>

            <Grid container justifyContent="space-between">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                disabled={!dirty || isLoading || isSuspendMutations}
              >
                Submit
              </Button>

              <Feature name="FEATURE_SEED_DATA">
                <Button
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={async () => {
                    const { faker } = await import("@faker-js/faker");

                    setValues(prevValues => ({
                      ...prevValues,
                      // customer_uuid: faker.helpers.arrayElement(
                      //   formOptionsData?.customers?.map(({ uuid }: any) => uuid)
                      // ),
                      lead_source_uuid: faker.helpers.arrayElement(
                        formOptionsData?.lead_source?.map(({ uuid }: any) => uuid)
                      ),
                      marketing_uuids: faker.helpers.arrayElements(
                        formOptionsData?.marketing?.map(({ uuid }: any) => uuid)
                      ),
                      lead_status_uuid: faker.helpers.arrayElement(
                        formOptionsData?.lead_status?.map(({ uuid }: any) => uuid)
                      ),
                      sales_rep_user_uuid: faker.helpers.arrayElement(
                        formOptionsData?.sales_rep_user?.map(({ uuid }: any) => uuid)
                      ),
                      notes: faker.random.words(5),
                      site_address: faker.address.streetAddress(true),
                      ...(parsedSearchParams?.initialValues as Record<string, string>)
                    }));
                  }}
                >
                  <RefreshIcon /> Seed Data
                </Button>
              </Feature>
            </Grid>

            <Dialog fullWidth open={!!openFormDialog} onClose={() => setOpenFormDialog(undefined)}>
              {openFormDialog}
            </Dialog>
          </form>
        </CardContent>
      </Card>
    </FormikProvider>
  );
};

export default LeadForm;

const CheckboxSiteAddrSameAsBillingAddr = ({ data }: { data: any }) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<any>();
  const { data: customer } = useQueryOneCustomer(values?.customer_uuid);

  const handleChangeSiteAddrSameAsBillingAddr = (sameAsBillingAddr: boolean) => {
    if (!customer || !sameAsBillingAddr) {
      return;
    }

    setFieldValue("site_address", customer.billing_address);
  };

  const isSiteAddressSameAsBillingAddress = (site_address?: string) => {
    if (!customer || !site_address) {
      return false;
    }

    return customer?.billing_address === site_address;
  };

  useEffect(() => {
    if (customer && !data?.site_address) {
      setFieldValue("site_address", customer.billing_address);
    }
  }, [customer, data, setFieldValue]);

  return (
    <Checkbox
      checked={isSiteAddressSameAsBillingAddress(values?.site_address)}
      onChange={e => handleChangeSiteAddrSameAsBillingAddr(e.target.checked)}
      disabled={isSubmitting}
    />
  );
};
