import { useMemo } from "react";

import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderEditCellParams
} from "@mui/x-data-grid-pro";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import {
  Chip as MuiChip,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { getGridOperatorsOnlyBy } from "../../utils/getGridStringOperatorsOnlyBy.ts";
import { useAuthAbility } from "../../hooks/useAuthAbility.ts";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";
import SalesRepGridAutocompleteField from "./data-grid/SalesRepGridAutocompleteField.tsx";
import { DateRangeOperator } from "../../components/data-grid-v2/DateRangeOperator.tsx";
import { useQueryLeadFormOptions } from "../../api/Lead.ts";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Edit as EditIcon } from "@mui/icons-material";
import useHealthChecker from "../../hooks/useHealthChecker.ts";
import LeadStatusGridAutocompleteField from "./data-grid/LeadStatusGridAutocompleteField.tsx";
import {
  CustomerAutoCompleteFilter,
  CustomerEmailAutoCompleteFilter
} from "../../components/data-grid-v2/AutoCompleteFilters.ts";

export interface LeadListColumnsProps {
  availability: string | null; // "deleted" or "archived"
  isInCustomerDetailsPage?: boolean;
  isInSalesRepDashboardPage?: boolean;
}

const Chip = styled(MuiChip)(spacing);

// TODO: Separate component file
const LightTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

export const useLeadListColumns = ({
  availability,
  isInCustomerDetailsPage,
  isInSalesRepDashboardPage
}: LeadListColumnsProps) => {
  const ability = useAuthAbility();
  const { data: formOptions } = useQueryLeadFormOptions();
  const { isSuspendMutations } = useHealthChecker();

  return useMemo<GridColDef[]>(() => {
    return [
      {
        field: "lead_number",
        headerName: "Lead number",
        width: 100,
        renderCell: params => {
          const {
            row: { lead_number, uuid }
          } = params;

          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/lead/:uuid/details", { uuid })}
            >
              {lead_number}
            </Link>
          );
        },
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"])
      },
      {
        field: "customer.email",
        headerName: "Customer Email",
        width: 250,
        filterable: !isInCustomerDetailsPage,
        renderCell: params => {
          const {
            row: { customer }
          } = params;

          if (!customer?.uuid) {
            return;
          }

          if (ability.cannot("UpdateCustomer", "customers")) {
            return customer.email;
          }

          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", {
                uuid: customer?.uuid
              })}
            >
              {customer.email}
            </Link>
          );
        },
        type: "singleSelect",
        filterOperators: [CustomerEmailAutoCompleteFilter]
      },
      {
        field: "customer.uuid",
        headerName: "Customer UUID",
        filterable: !isInSalesRepDashboardPage && !isInCustomerDetailsPage,
        valueGetter: (value, row) => row.customer?.uuid
      },
      {
        field: "customer_id",
        headerName: "Customer",
        width: 150,
        filterable: !isInCustomerDetailsPage,
        renderCell: params => {
          const {
            row: { customer }
          } = params;

          if (ability.cannot("Read", "customers")) {
            return customer.full_name_with_company;
          }

          if (customer) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                {customer.full_name_with_company}
              </Link>
            );
          }
        },
        type: "singleSelect",
        filterOperators: [CustomerAutoCompleteFilter]
      },
      {
        field: "customer.phone_number",
        headerName: "Phone Number",
        width: 150,
        valueGetter: (value, row) => {
          const { customer } = row;
          return customer?.phone_number;
        }
      },
      {
        field: "site_address",
        headerName: "Site Address",
        width: 500,
        sortable: false,
        valueGetter: (value, row) => {
          const { customer, site_address } = row;
          return site_address || `${customer?.billing_address} (From customer's billing address)`;
        }
      },
      {
        field: "lead_source",
        headerName: "Lead Source",
        width: 150,
        renderCell: ({ row }) => {
          const { lead_source } = row;

          if (lead_source) {
            if (ability.cannot("UpdateLeadSource", "lead_source")) {
              return lead_source?.name;
            }

            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/lead-source/:uuid/edit", {
                  uuid: lead_source?.uuid
                })}
              >
                {lead_source?.name}
              </Link>
            );
          }
        },
        type: "singleSelect",
        valueOptions: formOptions?.lead_source?.map((lead_source: any) => {
          return {
            value: lead_source.id,
            label: lead_source.name
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "marketings",
        headerName: "Marketing",
        width: 300,
        sortable: false,
        renderCell: ({ row }) => {
          const { marketings } = row;

          if (!marketings || marketings.length < 1) {
            return;
          }
          return Array.prototype.map
            .call(marketings, function (item) {
              return item.name;
            })
            .join(", ");
        }
      },
      {
        field: "lead_date",
        headerName: "Lead Date",

        renderCell: ({ row }) => {
          if (row.appointment_datetime_start === null) {
            return;
          }

          return format(new Date(row.appointment_datetime_start), "dd/MM/yyyy");
        },
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "lead_date_start_time",
        headerName: "Start Time",
        minWidth: 80,
        sortable: false,
        renderCell: ({ row }) => {
          if (row.appointment_datetime_start === null) {
            return;
          }

          return format(new Date(row.appointment_datetime_start), "p");
        },
        valueGetter: (value, row) => {
          if (row.appointment_datetime_start === null) {
            return;
          }

          return format(new Date(row.appointment_datetime_start), "kk:mm:ss");
        }
      },
      {
        field: "lead_date_end_time",
        headerName: "End Time",
        minWidth: 80,
        sortable: false,

        renderCell: ({ row }) => {
          if (row.appointment_datetime_end === null) {
            return;
          }

          return format(new Date(row.appointment_datetime_end), "p");
        },
        valueGetter: (value, row) => {
          if (row.appointment_datetime_end === null) {
            return;
          }

          return format(new Date(row.appointment_datetime_end), "kk:mm:ss");
        }
      },
      {
        field: "lead_status_id",
        headerName: "Lead Status",
        width: 160,
        renderCell: params => {
          const { row, id, field, api } = params;
          const { lead_status } = row;

          if (ability.cannot("UpdateLeadStatus", "lead_status")) {
            return lead_status?.name;
          }

          return (
            <LightTooltip
              placement="right-start"
              title={
                <MenuList>
                  <MenuItem
                    onClick={() => api.startCellEditMode({ id, field })}
                    disabled={isSuspendMutations}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Modify</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            >
              {lead_status ? (
                <Chip
                  label={
                    <Link
                      component={RouterLink}
                      underline="none"
                      to={generatePath("/lead-status/:uuid/edit", {
                        uuid: lead_status?.uuid
                      })}
                    >
                      <Typography
                        sx={{
                          color: `#000000`
                        }}
                      >
                        {lead_status?.name}
                      </Typography>
                    </Link>
                  }
                  key={row.uuid}
                  m={1}
                  sx={{
                    backgroundColor: lead_status?.color,
                    borderRadius: 12
                  }}
                />
              ) : (
                <Typography variant="caption">N/A</Typography>
              )}
            </LightTooltip>
          );
        },
        editable: true,
        renderEditCell: (props: GridRenderEditCellParams) => (
          <LeadStatusGridAutocompleteField {...props} />
        ),
        type: "singleSelect",
        valueOptions: formOptions?.lead_status?.map((lead_status: any) => {
          return {
            value: lead_status.id,
            label: lead_status.name
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "sales_rep_user.uuid",
        headerName: "Sales Rep UUID",
        filterable: !isInSalesRepDashboardPage,
        valueGetter: (value, row) => row.sales_rep_user?.uuid,
        type: "singleSelect",
        valueOptions: formOptions?.sales_rep_user?.map((user: any) => ({
          value: user.uuid,
          label: user.full_name
        })),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "sales_rep_user_id",
        headerName: "Sales Rep",
        filterable: !isInSalesRepDashboardPage,
        width: 160,
        renderCell: params => {
          const { row, id, field, api } = params;

          const { sales_rep_user } = row;

          if (ability.cannot("UpdateUser", "users")) {
            return sales_rep_user?.full_name;
          }

          return (
            <LightTooltip
              placement="right-start"
              title={
                <MenuList>
                  <MenuItem
                    onClick={() => api.startCellEditMode({ id, field })}
                    disabled={isSuspendMutations}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Modify</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            >
              {sales_rep_user ? (
                <Link
                  component={RouterLink}
                  underline="none"
                  to={generatePath("/users/:uuid/edit", {
                    uuid: sales_rep_user?.uuid
                  })}
                >
                  {sales_rep_user.full_name}
                </Link>
              ) : (
                <Typography variant="caption">N/A</Typography>
              )}
            </LightTooltip>
          );
        },
        editable: true,
        renderEditCell: (props: GridRenderEditCellParams) => (
          <SalesRepGridAutocompleteField {...props} />
        ),
        type: "singleSelect",
        valueOptions: formOptions?.sales_rep_user?.map((user: any) => ({
          value: user.id,
          label: user.full_name
        })),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "notes",
        headerName: "Notes",
        sortable: false,
        width: 300
      },
      {
        field: "created_at",
        headerName: "Created",
        width: 160,
        renderCell: ({ row }) => {
          if (row.created_at === null) {
            return;
          }

          return format(new Date(row.created_at), "dd/MM/yyyy p");
        },
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      }
    ];
  }, [ability, availability, isSuspendMutations]);
};
