import React, { useEffect, useMemo } from "react";
import { FallbackProps } from "react-error-boundary";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { OptionsObject, useSnackbar } from "notistack";
import useAuth from "../hooks/useAuth";
import { createErrorInfo } from "../utils/createErrorInfo";

const ErrorFallbackMessage = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { responseErrorCode, errorCode, responseReqTraceId, errorMessage } = useMemo(
    () => createErrorInfo(error),
    [error]
  );
  const { tokenExpired } = useAuth();

  useEffect(() => {
    const errorSnackbarOptions: OptionsObject = {
      variant: "error",
      preventDuplicate: true,
      key: errorCode
    };

    enqueueSnackbar(errorMessage, errorSnackbarOptions);
  }, [errorMessage, enqueueSnackbar, errorCode, responseErrorCode]);

  useEffect(() => {
    if (responseErrorCode === 401) {
      tokenExpired();
    }
  }, [responseErrorCode, tokenExpired]);

  return (
    <Alert severity="error">
      <>
        <AlertTitle>Error</AlertTitle>
        <Typography>{errorMessage}</Typography>
        <br />
        {!!responseReqTraceId && responseErrorCode !== 401 && (
          <Typography>
            You may send this Request ID for your reference and further help on troubleshooting:{" "}
            {responseReqTraceId}
          </Typography>
        )}
        <br />
        <Button onClick={() => resetErrorBoundary()}>Try again</Button>
      </>
    </Alert>
  );
};

export default ErrorFallbackMessage;
