import { AutoCompleteFilterInput } from "./AutoCompleteFilterInput.tsx";

export const CustomerAutoCompleteFilter = AutoCompleteFilterInput({
  initialQueryParams: {
    sort: "name"
  },
  queryKeyApi: "customers",
  mapRowToOption: (option: any) => ({
    id: option.id,
    label: option.full_name_with_company
  })
});

export const CustomerEmailAutoCompleteFilter = AutoCompleteFilterInput({
  initialQueryParams: {
    sort: "email"
  },
  queryKeyApi: "customers",
  mapRowToOption: (option: any) => ({
    id: option.email,
    label: option.email
  })
});

export const LeadAutoCompleteFilter = AutoCompleteFilterInput({
  initialQueryParams: {
    sort: "lead_number"
  },
  queryKeyApi: "lead",
  mapRowToOption: (option: any) => ({
    id: option.lead_number,
    label: option.lead_number.toString()
  })
});

export const OrderRefNumberAutoCompleteFilter = AutoCompleteFilterInput({
  initialQueryParams: {
    sort: "!reference_number"
  },
  queryKeyApi: "order",
  mapRowToOption: (option: any) => ({
    id: option.reference_number,
    label: option.reference_number
  })
});
